<template>
    <section class="mt-5">
        <TabMenu :links="subMenuLinks" />
    </section>
    <section class="mt-5">
        <router-view />
    </section>
</template>

<script>
    import LineChart from '@/components/chart/LineChart';
    import TabMenu from '@/components/general/TabMenu';

    export default {
        components: {
            LineChart,
            TabMenu,
        },
        // created() {
        //     this.$store.dispatch('energy_data/fetchElecEndexData').then(() => (this.isLoaded = true));
        // },
        data() {
            return {
                subMenuLinks: [
                    { to: { name: 'elecEndexData' }, display_name: this.$tc('endex', 1) },
                    { to: { name: 'elecMonthlyEndexData' }, display_name: this.$tc('endex_monthly', 1) },
                    { to: { name: 'epexSpotData' }, display_name: this.$tc('belpex', 1) },
                    { to: { name: 'epexSpotMonthlyData' }, display_name: this.$tc('monthly_epex_spot', 1) },
                ],
                isLoaded: false,
            };
        },
    };
</script>
